
            @import "@/assets/sass/vars.portal.scss";
          

























































































.missing-days-table {
    tr.day-type-free {
        background: $primary !important;

        td {
            color: white !important;
        }
    }
}
