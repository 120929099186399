
            @import "@/assets/sass/vars.portal.scss";
          




































































































































































































































































































































































































































































































































.v-my-report {
    dl {
        dt {
            font-weight: normal;
        }

        dd {
            font-size: 25px;
            font-weight: 600;
            margin-bottom: 20px;
        }
    }
}
